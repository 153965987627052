import { CognitoUser, ICookieStorageData } from "amazon-cognito-identity-js"
import { Amplify, Auth } from "aws-amplify"
import { CookieSameSiteOptions, LoginCredentials } from "./types"

let cookieSecure = true
let cookieSameSite: CookieSameSiteOptions = "strict"
const region = "eu-west-2"
let userPoolId = "eu-west-2_BDqxnIFeR"
let userPoolWebClientId = "6jm4fmg2rjign9ounv5jc6ljs4"
const { hostname } = window.location

switch (hostname) {
  case "localhost":
    cookieSecure = false
    cookieSameSite = "lax"
    break
  case "dev":
    break
  case "test":
    break
  case "prod":
    break
}

const cookieStorageData: ICookieStorageData = {
  domain: hostname,
  path: "/",
  secure: cookieSecure,
  sameSite: cookieSameSite,
}

const authOptions = {
  region,
  userPoolId,
  userPoolWebClientId,
  mandatorySignIn: false,
  authenticationFlowType: "",
  cookieStorage: cookieStorageData,
}

// Configure cognito client
Amplify.configure({
  Auth: authOptions,
})

/**
 * Check whether current user session is valid.
 * @returns boolean
 */
export async function cognitoIsCurrentSessionValid(): Promise<boolean> {
  const userSession = await Auth.currentSession()
  return userSession.isValid()
}

/**
 * Log in with Cognito
 * @param loginCredentials
 * @returns Promise
 */
export async function cognitoLogin(
  loginCredentials: LoginCredentials,
): Promise<CognitoUser> {
  const cognitoUser: CognitoUser = await Auth.signIn({
    username: loginCredentials.username,
    password: loginCredentials.password,
  })

  if (
    cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED" &&
    loginCredentials.newPassword !== ""
  ) {
    Auth.completeNewPassword(cognitoUser, loginCredentials.newPassword)
  }

  return cognitoUser
}

/**
 * Retrieve current session's ID token.
 * @returns string
 */
export async function cognitoGetIdToken(): Promise<string> {
  const userSession = await Auth.currentSession()
  return userSession.getIdToken().getJwtToken()
}

/**
 * Log out with Cognito
 * @returns Promise
 */
export function cognitoLogout() {
  return Auth.signOut()
}
