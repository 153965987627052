import { Box, Grid, Paper } from "@mui/material"
import DownloadOnTheAppStore from "../../assets/apple/download-on-the-app-store/black.svg"
import BackgroundLogo from "../../assets/elm/background.jpg"
import NarvanLogo from "../../assets/narvan/Narvan-logo-02.jpg"
import GetItOnGooglePlay from "../../assets/google/get-it-on-google-play/en_badge_web_generic.svg"

function Home() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width={"100%"}
      style={{
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <Paper elevation={3} sx={{ borderRadius: 5, width: 400, paddingX: 2 }}>
        <Grid container textAlign={"center"} paddingY={4}>
          <Grid item xs={12}>
            <img
              style={{ height: "80px" }}
              src={NarvanLogo}
              alt="Narvan Houseplants Logo"
            />
          </Grid>
          <Grid item xs={12}>
            <p>Hard to kill houseplants</p>
          </Grid>
          <Grid mb={2} item xs={12}>
            <p style={{ fontSize: 13 }}>
              Download the Narvan app for personalised plant care instructions,
              watering reminders and plant heath diagnosis.
            </p>
          </Grid>
          <Grid alignItems={"center"} item xs={6}>
            <a href="https://apps.apple.com/us/app/baby-coloring-book-for-kids-2/id1129192831">
              <img
                style={{ height: "40px" }}
                src={DownloadOnTheAppStore}
                alt="Download on the App Store"
              />
            </a>
          </Grid>
          <Grid item alignItems={"center"} xs={6}>
            <a href="https://play.google.com/store/apps/details?id=uk.co.elmplants.app">
              <img
                style={{ height: "40px" }}
                src={GetItOnGooglePlay}
                alt="Get it on Google Play"
              />
            </a>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default Home
