import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackgroundLogo from "../../assets/elm/background.jpg"
import { logout } from "../features/auth/authThunks"
import { useAppDispatch } from "../hooks"
import { useDeleteSelfMutation } from "../services/elmApi"

function DeleteAccount() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deleteSelf, { isLoading, isError, isSuccess }] =
    useDeleteSelfMutation()
  const [isDeleted, setIsDeleted] = useState(false)

  const handleDeleteAccount = () => {
    deleteSelf({})
    setIsDeleted(true)
  }

  const handleLogout = () => {
    dispatch(logout())
    navigate("/")
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width={"100%"}
      style={{
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 5,
          width: 400,
          paddingX: 3,
        }}
      >
        <Grid
          container
          textAlign={"center"}
          justifyContent={"center"}
          paddingY={2}
        >
          {" "}
          <Grid
            item
            xs={12}
            mb={2}
            mt={0}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              disabled={isLoading}
              onClick={handleLogout}
              style={{ fontSize: 16 }}
            >
              X
            </Button>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h5">Delete Account</Typography>
          </Grid>
          <Grid item xs={10} mb={1}>
            <Typography color={"primary"}>
              Are you sure you want to delete your account?
            </Typography>
          </Grid>
          <Grid item xs={5.5} mb={1}>
            <Button
              variant="contained"
              type="submit"
              style={{
                marginTop: 20,
                width: "100%",
                borderRadius: 6,
                marginRight: 8,
              }}
              onClick={handleDeleteAccount}
              disabled={isSuccess || isLoading || (!isError && isDeleted)}
            >
              Yes, delete
            </Button>
          </Grid>
          <Grid item xs={5.5} mb={1}>
            <Button
              variant="outlined"
              type="submit"
              style={{
                marginTop: 20,
                width: "100%",
                borderRadius: 6,
                marginLeft: 8,
              }}
              onClick={handleLogout}
              disabled={isSuccess || isLoading || (!isError && isDeleted)}
            >
              Cancel
            </Button>
          </Grid>
          {isError && (
            <Typography variant="h6" mt={2} color={"red"}>
              There was an error, try again.
            </Typography>
          )}
          {isSuccess && (
            <Typography variant="h6" mt={2} color={"green"}>
              Account has been successfully deleted.
            </Typography>
          )}
        </Grid>
      </Paper>
    </Box>
  )
}

export default DeleteAccount
