import { CssBaseline, ThemeProvider, colors, createTheme } from "@mui/material"
import RootLayout from "./Layouts/RootLayout"
import Session from "./features/auth/Session"
import FaqPage from "./pages/Faq-page"
import Home from "./pages/Home"
import PrivacyPage from "./pages/Privacy-policy"
import TermsPage from "./pages/Terms-and-conditions"

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="terms" element={<TermsPage />} />
      <Route path="faq" element={<FaqPage />} />
      <Route path="delete" element={<Session />} />
    </Route>,
  ),
)

const theme = createTheme({
  palette: {
    primary: {
      main: "#224E3F",
      dark: colors.teal[900],
      light: "#E6DAF2",
    },
    secondary: {
      main: "#DAF2EA",
      light: "#ffffff",
      dark: "#9887A8",
    },
  },
  typography: {
    body2: {
      fontWeight: "bold",
    },
  },
})
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
