/**
 * Calculate local or remote backend URL from frontend URL
 *
 * @param hostname
 * @returns backend URL
 */
export function getBackendUrl(hostname: string | undefined): string {
  if (hostname === undefined) {
    hostname = window.location.hostname
  }

  // Default to local URL
  let backendUrl: string = "http://127.0.0.1:7800"

  // Remote URLs
  if (hostname.endsWith("app.elmplants.co.uk")) {
    let hostnameParts = hostname.split(".")
    hostnameParts.unshift("api")
    backendUrl = `https://${hostnameParts.join(".")}`
  }

  return backendUrl
}
