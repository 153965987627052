import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store"
import { ApplicationState } from "./types"

const initialState: ApplicationState = {
  version: import.meta.env.VITE_APP_VERSION || "0.0.0",
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
})

export const selectVersion = (state: RootState) => state.application.version

export default applicationSlice.reducer
