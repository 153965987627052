import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  cognitoGetIdToken,
  cognitoIsCurrentSessionValid,
  cognitoLogin,
  cognitoLogout,
} from "./AuthAPI"
import { LoginCredentials, LoginResponse } from "./types"

// A Thunk allows async logic and can be dispatched like a regular action.

export const isCurrentSessionValid = createAsyncThunk(
  "auth/isCurrentSessionValid",
  async (): Promise<boolean> => {
    const isLoggedIn = await cognitoIsCurrentSessionValid()
    return isLoggedIn
  },
)

export const login = createAsyncThunk(
  "auth/login",
  async (
    loginCredentials: LoginCredentials,
  ): Promise<LoginResponse | undefined> => {
    const cognitoUser = await cognitoLogin(loginCredentials)
    // The value returned becomes the `fulfilled` action payload
    const loginResponse: LoginResponse = {
      username: cognitoUser.getUsername(),
      authenticated: cognitoUser.challengeName ? false : true,
      challenge: cognitoUser.challengeName,
    }

    return loginResponse
  },
)

export const getIdToken = createAsyncThunk(
  "auth/getIdToken",
  async (): Promise<string> => {
    const token = await cognitoGetIdToken()
    return token
  },
)

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await cognitoLogout()
  // The value we return becomes the `fulfilled` action payload
  return response.success
})
