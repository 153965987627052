import { Outlet } from "react-router-dom"
import { useAppSelector } from "../hooks"
import { selectVersion } from "../features/application/applicationSlice"

function RootLayout() {
  const appVersion = useAppSelector(selectVersion)
  console.info(`App version: ${appVersion}`)

  return (
    <>
      <Outlet />
    </>
  )
}

export default RootLayout
